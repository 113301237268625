<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th-large f-s-20", title: "Dashboard" },
  {
    path: "/reboan",
    icon: "fa fa-calendar-alt f-s-20",
    title: "Rabu Anies",
  },
];

export default sidebarMenu;
</script>
