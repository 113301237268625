export default {
  data() {
    return {
      userData: null,
      userName: null,
      userEmail: null,
      userLevel: null,
      userAccess: null,
      userToken: null,
      userPhoto: "../assets/img/user.png",
    };
  },
  created() {
    const userData = localStorage.getItem("userData");
    if (userData) {
      this.userData = JSON.parse(userData);
      this.userName = this.userData.nama;
      this.userEmail = this.userData.email;
      this.userLevel = this.userData.level;
      this.userAccess = this.userData.hak_akses;
      this.userToken = this.userData.token;
      this.userPhoto = this.userData.foto;
    }
  },
};
