<template>
  <div>
    <h1 class="page-header">Dashboard <small></small></h1>

    <div class="row">
      <div class="col-md-12">
        <div>
          <select
            name="filter"
            class="form-control m-b-5"
            v-model="provinsi"
            v-on:change="getProvinsi($event)"
          >
            <option value=""></option>
            <option value="All">Semua Provinsi</option>
          </select>
        </div>
        <div
          ref="mapChart"
          id="mapChart"
          style="height: 70vh; border-radius: 5px; overflow: hidden"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";

export default {
  name: "dashboard",
  data() {
    return {
      mapChart: null,
      provinsi: "",
      option: "",
      linkMaps: "/geojson/00.geojson",
      provinsiName: "",
      idProvinsi: "00",
      reboanAll: [],
      reboanProv: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/api/dashboard")
        .then((response) => {
          this.reboanAll = response.data.maps;
          this.showIndonesiaMap();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchProvinsi(param) {
      axios
        .get("/api/dashboard/provinsi/" + param)
        .then((response) => {
          this.reboanProv = response.data.maps_provinsi;
          // console.log(this.reboanProv);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi(event) {
      this.provinsi = event.target.value;
      if (this.provinsi == "All") {
        this.myChart.dispose();
        this.option = "";
        this.showIndonesiaMap();
      }
    },
    showIndonesiaMap() {
      this.linkMaps = "/geojson/00.geojson";
      this.myChart = echarts.init(this.$refs["mapChart"]);
      this.myChart.showLoading();
      axios
        .get(this.linkMaps)
        .then((response) => {
          this.myChart.hideLoading();
          echarts.registerMap("INA", response.data);
          this.myChart.setOption(
            (this.option = {
              title: {
                text: "PETA KEGIATAN RABU ANIES",
                left: "center",
                top: 15,
              },
              backgroundColor: "#eee",
              tooltip: {
                trigger: "item",
                // formatter: "{b}<br/>{c} Pertemuan",
                formatter: function (params) {
                  return (
                    params.name + "<br>" + (params.value || 0) + " Pertemuan"
                  );
                },
              },
              toolbox: {
                show: false,
                orient: "vertical",
                left: "right",
                top: "center",
                feature: {
                  dataView: { readOnly: false },
                  restore: {},
                  saveAsImage: {},
                },
              },
              visualMap: {
                min: 0,
                max: 10000,
                inRange: {
                  color: ["lightskyblue", "yellow", "orangered"],
                },
                outOfRange: {
                  color: "white",
                },
                show: true,
                text: ["Tertinggi", "Terendah"],
                calculable: true,

                realtime: false,
              },
              series: [
                {
                  name: "provinsi",
                  type: "map",
                  map: "INA",
                  roam: true,
                  scaleLimit: {
                    min: 1.2,
                    max: 10,
                  },
                  zoom: 1.2,
                  label: {
                    show: false,
                    formatter: function (params) {
                      return params.value || 0;
                    },
                  },
                  data: this.reboanAll,
                  itemStyle: {
                    color: ["green", "blue"],
                  },
                },
              ],
            })
          );
          this.option && this.myChart.setOption(this.option);

          this.myChart.on("click", (params) => {
            if (params.data) {
              this.idProvinsi = params.data.id;
              this.showMap(params.name, this.idProvinsi);
            }
          });
        })
        .catch((error) => {
          console.error("Error loading GeoJSON:", error);
          this.myChart.hideLoading();
        });
    },

    showMap(name, id) {
      this.myChart.dispose();
      this.option = "";
      this.myChart = echarts.init(this.$refs["mapChart"]);
      this.provinsi = "";

      if (id != "00") {
        this.linkMaps = "/geojson/" + id + ".geojson";
        this.provinsiName = name;
      }

      this.myChart.showLoading();
      axios
        .get("/api/dashboard/provinsi/" + id)
        .then((response) => {
          this.reboanProv = response.data.maps_provinsi;
          axios
            .get(this.linkMaps)
            .then((response) => {
              this.myChart.hideLoading();
              echarts.registerMap("PROV", response.data);
              this.myChart.setOption(
                (this.option = {
                  title: {
                    text:
                      "PETA KEGIATAN RABU ANIES PROVINSI " + this.provinsiName,
                    left: "center",
                    top: 15,
                  },
                  backgroundColor: "#eee",
                  tooltip: {
                    trigger: "item",
                    // formatter: "{b}<br/>{c} (p / km2)",
                    formatter: function (params) {
                      return (
                        params.name +
                        "<br>" +
                        (params.value || 0) +
                        " Pertemuan"
                      );
                    },
                  },
                  toolbox: {
                    show: false,
                    orient: "vertical",
                    left: "right",
                    top: "center",
                    feature: {
                      dataView: { readOnly: false },
                      restore: {},
                      saveAsImage: {},
                    },
                  },
                  visualMap: {
                    min: 0,
                    max: 1000,
                    text: ["High", "Low"],
                    realtime: false,
                    calculable: true,
                    inRange: {
                      color: ["lightskyblue", "yellow", "orangered"],
                    },
                  },
                  series: [
                    {
                      name: "provinsi",
                      type: "map",
                      map: "PROV",
                      roam: true,
                      scaleLimit: {
                        min: 1,
                        max: 10,
                      },
                      zoom: 1,
                      label: {
                        show: false,
                        formatter: function (params) {
                          return params.value || 0;
                        },
                      },
                      data: this.reboanProv,
                      itemStyle: {
                        // Set the color for this series
                        color: ["green", "blue"], // You can use hex color codes or named colors
                      },
                    },
                  ],
                })
              );

              this.option && this.myChart.setOption(this.option);
            })
            .catch((error) => {
              console.error("Error loading GeoJSON:", error);
              this.myChart.hideLoading();
            });
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>