<template>
  <div class="login login-v2 p-3">
    <div class="bg-dark" style="padding: 50px 0px; border-radius: 10px">
      <!-- begin brand -->
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span> <b>RABU</b> ANIES
          <small>Silahkan masuk untuk melanjutkan</small>
        </div>
      </div>
      <!-- end brand -->
      <!-- begin login-content -->
      <div class="login-content">
        <form @submit.prevent="login" method="POST" class="margin-bottom-0">
          <div class="form-group m-b-15">
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Email/Username"
              id="username"
              v-model="username"
              required
            />
          </div>
          <div class="form-group m-b-15">
            <input
              type="password"
              class="form-control form-control-lg"
              placeholder="Password"
              id="password"
              v-model="password"
              required
            />
          </div>
          <b-alert v-if="alert == 'error'" variant="danger" show fade>
            <strong>Login Gagal!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <b-alert v-if="alert == 'success'" variant="success" show fade>
            <strong>Login Success!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <div class="login-buttons">
            <button type="submit" class="btn btn-teal btn-block btn-lg">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Login
            </button>
          </div>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; IDETech All Right Reserved 2023
          </p>
        </form>
      </div>
    </div>
    <!-- end login-content -->
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-grey";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      this.alert = "";
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      axios
        .post("/api/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;

            setTimeout(() => {
              const userData = {
                nama: response.data.nama,
                email: response.data.email,
                foto: response.data.foto,
                level: response.data.level,
                hak_akses: response.data.hak_akses,
                token: response.data.token,
              };
              localStorage.setItem("userData", JSON.stringify(userData));
              if (response.data.level === "admin") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "user") {
                this.$router.push("/dashboard");
              } else {
                this.$router.push("/dashboard");
              }
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>