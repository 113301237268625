<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Daftar Kegiatan Rabu Anies</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions"></div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <!-- <router-link
                  :to="'/reboan/detail/' + props.row.id_reboan"
                  class="btn btn-sm btn-success m-r-5"
                >
                  <i class="fa fa-search"></i>
                </router-link> -->
                <button
                  class="btn btn-sm btn-danger"
                  v-on:click="confirmDelete(props.row.id_reboan)"
                >
                  <i class="fa fa-trash-alt"></i>
                </button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "tanggal",
          width: "140px",
          thClass: "text-nowrap text-center",
          tdClass: "text-nowrap text-center",
        },
        {
          label: "Nama",
          field: "nama",
          width: "210px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nomor HP/WA",
          field: "no_hp",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Provinsi",
          field: "nama_provinsi",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kabupaten",
          field: "nama_kabupaten",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jumlah Peserta",
          field: "jml_peserta",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "50px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        level: "",
      },
      link: "/api/reboan/",
      nik: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const user = localStorage.getItem("user");
      this.link = "/api/reboan/";
      if (user) {
        this.user = JSON.parse(user);
        if (this.user.level == "User") {
          this.link = this.link + this.user.nik;
        }
      } else {
        //
      }
      axios
        .get(this.link)
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    editRow(id) {
      window.alert(id);
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Dibatalkan", "Data Batal Dihapus", "error");
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/reboan/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
      // window.alert("Hapus Data dengan id: " + id);
    },
  },
};
</script>