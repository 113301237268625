<template>
  <div style="padding: 0px 10px">
    <div class="row">
      <div class="col-md-12 bg-blue p-lg-5">
        <!-- <h1 style="font-weight: bold; text-align: center"> -->
        <h2 class="text-center text-white m-t-5" style="font-weight: bolder">
          KEGIATAN RABU ANIES
        </h2>
        <!-- <p class="text-center text-white" style="font-size: 18px">
          "Saya mengharap mari kita prioritaskan kerja-kerja horizontal yang
          masif, kerja yang intensif, kerja yang berkelanjutan. Dan itu artinya
          mengajak mulai dari yang ada di sekitaran kita, yang terdekat dengan
          kita untuk menjadi barisan yang memilih melakukan perubahan dan
          perbaikan"
        </p>
        <p class="text-center text-white">
          <strong style="font-size: 18px">- Anies Baswedan -</strong>
        </p>
        <div style="text-align: center" class="m-b-5">
          <router-link class="btn btn-lg btn-light text-center" to="/login"
            >Infokan Reboan</router-link
          >
        </div> -->
      </div>
      <div class="col-md-12">
        <div class="row p-lg-4">
          <div class="col-md-3" style="padding: 20px"></div>
          <div
            class="col-md-6"
            style="background-color: #eee; padding: 20px; border-radius: 5px"
          >
            <form @submit.prevent="postData" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="nama"
                    >Nama <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    name="nama"
                    class="form-control"
                    v-model="form.nama"
                    :class="{
                      'is-invalid': formValidate.nama,
                    }"
                  />
                </div>

                <div class="form-group col-md-8">
                  <label for="email"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    v-model="form.email"
                    :class="{
                      'is-invalid': formValidate.email,
                    }"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label for="no_hp"
                    >Nomor HP(WA) <span class="text-danger">*</span></label
                  >
                  <input
                    type="number"
                    name="no_hp"
                    class="form-control"
                    v-model="form.no_hp"
                    :class="{
                      'is-invalid': formValidate.no_hp,
                    }"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="provinsi">Provinsi</label>
                  <select
                    v-model="form.provinsi"
                    class="form-control default-select2"
                    name="provinsi"
                    v-on:change="getKabupaten($event)"
                    :class="{
                      'is-invalid': formValidate.provinsi,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="provinsi in opsiProvinsi"
                      :key="provinsi.id"
                      :value="provinsi.id"
                    >
                      {{ provinsi.nama_provinsi }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="kabupaten">Kabupaten</label>
                  <select
                    v-model="form.kabupaten"
                    name="kabupaten"
                    class="form-control m-b-5"
                    v-on:change="getKecamatan($event)"
                    :class="{
                      'is-invalid': formValidate.kabupaten,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kabupaten in opsiKabupaten"
                      :key="kabupaten.id"
                      :value="kabupaten.id"
                    >
                      {{ kabupaten.nama_kabupaten }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="kecamatan">Kecamatan</label>
                  <select
                    v-model="form.kecamatan"
                    name="kecamatan"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.kecamatan,
                    }"
                    v-on:change="getKelurahan($event)"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kecamatan in opsiKecamatan"
                      :key="kecamatan.id"
                      :value="kecamatan.id"
                    >
                      {{ kecamatan.nama_kecamatan }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="kelurahan">Kelurahan</label>
                  <select
                    v-model="form.kelurahan"
                    name="kelurahan"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.kelurahan,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kelurahan in opsiKelurahan"
                      :key="kelurahan.id"
                      :value="kelurahan.id"
                    >
                      {{ kelurahan.nama_kelurahan }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="simpul"
                    >Simpul <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    name="simpul"
                    class="form-control"
                    v-model="form.simpul"
                    :class="{
                      'is-invalid': formValidate.simpul,
                    }"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="jml_peserta"
                    >Jumlah Peserta <span class="text-danger">*</span></label
                  >
                  <input
                    type="number"
                    name="jml_peserta"
                    class="form-control"
                    v-model="form.jml_peserta"
                    :class="{
                      'is-invalid': formValidate.jml_peserta,
                    }"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="peserta"
                    >Nama Peserta (Pisahkan dengan tanda koma)
                    <span class="text-danger">*</span></label
                  >
                  <textarea
                    name="peserta"
                    class="form-control"
                    rows="3"
                    v-model="form.peserta"
                    :class="{
                      'is-invalid': formValidate.peserta,
                    }"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label for="aspirasi"
                    >Persoalan Bersama / Aspirasi
                    <span class="text-danger">*</span></label
                  >
                  <textarea
                    name="aspirasi"
                    class="form-control"
                    rows="3"
                    v-model="form.aspirasi"
                    :class="{
                      'is-invalid': formValidate.aspirasi,
                    }"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label for="hambatan_kemudahan"
                    >Hambatan & kemudahan dalam melaksanakan Musyawarah Reboan
                    <span class="text-danger">*</span></label
                  >
                  <textarea
                    name="hambatan_kemudahan"
                    class="form-control"
                    rows="3"
                    v-model="form.hambatan_kemudahan"
                    :class="{
                      'is-invalid': formValidate.hambatan_kemudahan,
                    }"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <input
                    style="display: none"
                    ref="input"
                    type="file"
                    name="image"
                    accept="image/*"
                    @change="setImage"
                  />
                  <a
                    href="#"
                    class="btn btn-primary m-1 btn-block"
                    role="button"
                    @click.prevent="showFileChooser"
                    v-b-modal.modalDialog
                    :class="{
                      'btn-danger': formValidate.foto_dokumentasi,
                    }"
                  >
                    <i class="fa fa-fw fa-image"></i> Unggah Foto Dokumentasi
                  </a>
                  <div
                    class="text-danger"
                    v-html="formValidate.foto_dokumentasi"
                  ></div>
                </div>
                <div class="form-group col-md-12" v-if="imgSrc">
                  <img
                    :src="imgSrc"
                    alt="foto"
                    style="width: 100%; border-radius: 5px; overflow: hidden"
                  />
                </div>

                <div class="form-group col-md-12">
                  <span class="text-danger"
                    >* Dengan ini Saya bersedia untuk menerima arahan dan
                    informasi dari Bapak <strong>Anies Baswedan</strong>.</span
                  >
                </div>

                <div class="col-md-12">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary btn-block"
                    @click="postData()"
                    :disabled="this.loading"
                  >
                    <i
                      v-if="this.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    Infokan Kegiatan
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-3" style="padding: 20px"></div>
        </div>
      </div>
      <div class="col-md-12 bg-dark p-lg-3 text-center">
        <span class="text-white"> &copy; IDETech All Right Reserved 2023 </span>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: {
        nama: "",
        email: "",
        no_hp: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        simpul: "",
        jml_peserta: "",
        peserta: "",
        aspirasi: "",
        hambatan_kemudahan: "",
        foto_dokumentasi: "",
      },
      opsiSimpul: [],
      opsiProvinsi: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      formValidate: [],
      loading: false,
      imgSrc: "",
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-grey";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  mounted() {
    this.getProvinsi();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();

      formData.append("nama", this.form.nama);
      formData.append("email", this.form.email);
      formData.append("no_hp", this.form.no_hp);
      formData.append("provinsi", this.form.provinsi);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("simpul", this.form.simpul);
      formData.append("jml_peserta", this.form.jml_peserta);
      formData.append("peserta", this.form.peserta);
      formData.append("aspirasi", this.form.aspirasi);
      formData.append("hambatan_kemudahan", this.form.hambatan_kemudahan);
      formData.append("foto_dokumentasi", this.form.foto_dokumentasi);

      // console.log(formData.get("foto"));
      axios
        .post("/api/reboan/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              // this.$router.push("/");
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi() {
      axios
        .get("/api/daerah/provinsi")
        .then((response) => {
          this.opsiProvinsi = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten(event) {
      this.form.provinsi = event.target.value;
      axios
        .get("/api/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    showFileChooser() {
      this.$refs.input.click();
    },

    setImage(e) {
      const file = e.target.files[0];
      this.form.foto_dokumentasi = file;

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
};
</script>
