import axios from "axios";
import Swal from "sweetalert2";

const username = "@adminr3b0An20244An13S";
const password = "CeHc5E8Quv6hN1Hr";
const account = btoa(`${username}:${password}`);

axios.interceptors.request.use((config) => {
  // config.baseURL = "http://localhost/api/reboan";
  config.baseURL = "https://api-reboan.koreanies.com";

  config.headers.Authorization = `Basic ${account}`;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
