import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "../pages/Home.vue";
import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";
import InfoReboan from "../pages/InfoReboan.vue";

import Reboan from "../pages/reboan/Index.vue";

import Profil from "../pages/profil/Profil.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  { path: "*", redirect: "/dashboard/" },
  { path: "/home", component: Home },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/info-reboan", component: InfoReboan },
  { path: "/reboan", component: Reboan },
  { path: "/profil", component: Profil },
  { path: "/profil/:page", component: Profil },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("userData");
  if (
    to.path !== "/home" &&
    !isLoggedIn &&
    to.path !== "/login" &&
    to.path !== "/register" &&
    to.path !== "/info-reboan"
  ) {
    next("/home");
  } else if (to.path === "/home" && isLoggedIn) {
    next("/dashboard/");
  } else {
    next();
  }
});

export default router;
