var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.pageOptions.pageEmpty)?_c('div',{staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{
    'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified,
    'page-content-full-height': _vm.pageOptions.pageContentFullHeight,
    'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar,
    'page-with-two-sidebar': _vm.pageOptions.pageWithTwoSidebar,
    'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
    'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
    'page-with-top-menu': _vm.pageOptions.pageWithTopMenu,
    'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
    'has-scroll': _vm.pageOptions.pageBodyScrollTop,
  }},[_c('Header'),(_vm.pageOptions.pageWithTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar'):_vm._e(),_c('div',{staticClass:"content",class:{
      'content-full-width': _vm.pageOptions.pageContentFullWidth,
      'content-inverse-mode': _vm.pageOptions.pageContentInverseMode,
    },attrs:{"id":"content"}},[_c('router-view'),_c('vue-ins-progress-bar')],1),(_vm.pageOptions.pageWithFooter)?_c('Footer'):_vm._e()],1):_c('div',{staticClass:"h-100"},[_c('router-view'),_c('vue-ins-progress-bar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }